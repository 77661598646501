import { createRoot } from "react-dom/client";
import App from "./components/App/App";
import GlobalStyles from "./styles/GlobalStyles";

createRoot(document.getElementById("root")).render(
  <>
    <GlobalStyles />
    <App />
  </>
);
