import { thunk } from "easy-peasy";
import {
  createDocumentsAPICall,
  deleteDocumentsAPICall,
  duplicateDocumentsAPICall,
  getDocumentsAPICall,
  updateDocumentsAPICall,
} from "../../api/document";
import { getFileAPICall } from "../../api/file";
import { getSettingAPICall } from "../../api/setting";

const thunks = {
  toggleDocumentThunk: thunk(async (actions, docIndex, helpers) => {
    if (helpers.getStoreState().activeDocuments.includes(docIndex))
      actions.collapseDocumentAction(docIndex);
    else actions.expandDocumentAction(docIndex);
  }),
  getDocumentsThunk: thunk(async (actions, guid) => {
    actions.setLoadingAction(true);

    try {
      const documentsResponse = await getDocumentsAPICall(guid);
      actions.setUserDocumentAction(documentsResponse.data.user);
      actions.setClientDocumentAction(documentsResponse.data.client);
      actions.resetSettingStateAction();
      actions.resetFileStateAction();
      actions.setActiveDocumentsAction(["0"]);
    } catch (e) {
      actions.setAlertThunk({
        type: "danger",
        message: e.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  updateDocumentsThunk: thunk(
    async (
      actions,
      { userInput, clientInput, settingInput, fileInput },
      helpers
    ) => {
      actions.setLoadingAction(true);

      try {
        const updatedDocumentsResponse = await updateDocumentsAPICall({
          user: userInput,
          client: clientInput,
          setting: settingInput,
          file: fileInput,
        });
        updatedDocumentsResponse.data.user._id &&
          actions.setAlertThunk({
            type: "success",
            message: "User updated",
          });
        updatedDocumentsResponse.data.client._id &&
          actions.setAlertThunk({
            type: "success",
            message: "Client updated",
          });
        updatedDocumentsResponse.data.setting._id &&
          actions.setAlertThunk({
            type: "success",
            message: "Setting updated",
          });
        updatedDocumentsResponse.data.file._id &&
          actions.setAlertThunk({
            type: "success",
            message: "File updated",
          });

        const [userClientResponse, settingResponse, fileResponse] =
          await Promise.all([
            getDocumentsAPICall(updatedDocumentsResponse.data.client.guid),
            updatedDocumentsResponse.data.setting._id
              ? getSettingAPICall(updatedDocumentsResponse.data.setting._id)
              : Promise.resolve({ data: settingInput }),
            updatedDocumentsResponse.data.file._id
              ? getFileAPICall(updatedDocumentsResponse.data.file._id)
              : Promise.resolve({ data: fileInput }),
          ]);
        actions.setUserDocumentAction(userClientResponse.data.user);
        actions.setClientDocumentAction(userClientResponse.data.client);
        actions.setSettingDocumentAction(settingResponse.data);
        actions.setFileDocumentAction(fileResponse.data);
      } catch (e) {
        actions.setAlertThunk({
          type: "error",
          message: e.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  createDocumentsThunk: thunk(
    async (actions, { navigate, user, client }, helpers) => {
      actions.setLoadingAction(true);

      try {
        const documentsResponse = await createDocumentsAPICall({
          user,
          client,
        });
        actions.setUserDocumentAction(documentsResponse.data.user);
        actions.setClientDocumentAction(documentsResponse.data.client);
        navigate("/");
      } catch (e) {
        actions.setAlertThunk({
          type: "error",
          message: e.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  duplicateDocumentsThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const documentsResponse = await duplicateDocumentsAPICall({
        guidSource: helpers.getStoreState().user.document.clientGuid,
        name: helpers.getStoreState().documentDuplicationInput.name,
        email: helpers.getStoreState().documentDuplicationInput.email,
        password: helpers.getState().documentDuplicationInput.password,
        cpassword: helpers.getState().documentDuplicationInput.cpassword,
      });
      actions.setUserDocumentAction(documentsResponse.data.user);
      actions.setClientDocumentAction(documentsResponse.data.client);
      actions.resetSettingStateAction();
      actions.resetFileStateAction();
      actions.resetDocumentDuplicationInputAction();
      actions.setShowDuplicateDocumentsModalAction(false);
      actions.setActiveDocumentsAction(["0"]);
    } catch (e) {
      actions.setAlertThunk({
        type: "error",
        message: e.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  deleteDocumentsThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      await deleteDocumentsAPICall(
        helpers.getStoreState().user.document.clientGuid
      );
      actions.resetFileStateAction();
      actions.resetSettingStateAction();
      actions.setShowDeleteDocumentsModalAction(false);
      await actions.searchUsersThunk();
    } catch (e) {
      actions.setAlertThunk({
        type: "error",
        message: e.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
