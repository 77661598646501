import { thunk } from "easy-peasy";
import { searchUsersAPICall } from "../../api/user";

const thunks = {
  searchUsersThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const searchResults = await searchUsersAPICall(
        helpers.getStoreState().user.searchInput
      );
      actions.setUserSearchResultsAction(searchResults.data);
      actions.resetUserDocumentAction();
      actions.resetClientDocumentAction();
    } catch (e) {
      actions.setUserSearchResultsAction([]);
      actions.setAlertThunk({
        type: "danger",
        message: e.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
