import { thunk } from "easy-peasy";
import { loginAPICall, resetPasswordAPICall } from "../../api/auth";
import { links } from "../../utilities";
import { setAuthToken } from "../../utilities/api";

const thunks = {
  loginThunk: thunk(async (actions, { navigate, ...loginInput }, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await loginAPICall(loginInput);
      setAuthToken(response.data.token);
      actions.setIsLoggedInAction(true);
      navigate(links.HOME);
    } catch (e) {
      actions.setAlertThunk({
        type: "error",
        message: e.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  resetPasswordThunk: thunk(
    async (actions, { navigate, ...newPasswordInput }, helpers) => {
      actions.setLoadingAction(true);

      try {
        const userResponse = await resetPasswordAPICall(newPasswordInput);
        actions.setAlertThunk({
          type: "success",
          message: "Password has been reset successfully.",
        });
        actions.setUserDocumentAction(userResponse.data);
        navigate(-1);
      } catch (e) {
        actions.setAlertThunk({
          type: "danger",
          message: e.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
};

export default thunks;
