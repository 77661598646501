import { action } from "easy-peasy";
import defaultState from "./state";

const actions = {
  setActiveDocumentsAction: action((state, indexes) => {
    state.activeDocuments = indexes;
  }),
  expandDocumentAction: action((state, index) => {
    state.activeDocuments = [...state.activeDocuments, index];
  }),
  collapseDocumentAction: action((state, index) => {
    state.activeDocuments = state.activeDocuments.filter(
      (docIndex) => docIndex !== index
    );
  }),
  setShowDuplicateDocumentsModalAction: action((state, show) => {
    state.showDuplicateDocumentsModal = show;
  }),
  setShowDeleteDocumentsModalAction: action((state, show) => {
    state.showDeleteDocumentsModal = show;
  }),
  setDocumentDuplicationInputAction: action((state, input) => {
    state.documentDuplicationInput = {
      ...state.documentDuplicationInput,
      ...input,
    };
  }),
  resetDocumentDuplicationInputAction: action((state) => {
    state.documentDuplicationInput = defaultState.documentDuplicationInput;
  }),
};

export default actions;
