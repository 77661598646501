import { action } from "easy-peasy";
import { defaultState } from "./state";

const actions = {
  setSettingAction: action((state, setting) => {
    state.setting = {
      ...state.setting,
      ...setting,
    };
  }),
  setSettingDocumentAction: action((state, document) => {
    state.setting.document = {
      ...state.setting.document,
      ...document,
    };
  }),
  setShowAddSettingModalAction: action((state, show) => {
    state.setting.showAddSettingModal = show;
  }),
  setSettingSearchResultsAction: action((state, list) => {
    state.setting = defaultState;
    state.setting.searchResults = list;
  }),
  setSettingSearchInputAction: action((state, input) => {
    state.setting.searchInput = input;
  }),
  resetSettingStateAction: action((state) => {
    state.setting = defaultState;
    state.setting.searchResults = [];
  }),
};

export default actions;
