import Page from "./Page";
import Center from "../presentational/Center";
import { Form } from "react-bootstrap";

function Login() {
  return (
    <Page>
      <Center.Screen>
        <Form.Login />
      </Center.Screen>
    </Page>
  );
}

export default Login;
