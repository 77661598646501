import { useStoreActions, useStoreState } from "easy-peasy";
import Page from "../containers/Page";
import Accordion from "../containers/Accordion";
import Button from "../containers/Button";
import DocumentForm from "../containers/DocumentForm";
import Document from "../presentational/Document";
import Field from "../presentational/Field";
import { useFields } from "../containers/Form/FormPasswordReset";
import { useEffect } from "react";

function usePasswordFields() {
  const fields = useFields();
  return fields.map((field) => ({
    ...field,
    type: "text",
    col: { xs: 12, md: 6 },
  }));
}

function AddNewDocuments() {
  const { activeDocuments } = useStoreState((state) => state);

  const { setActiveDocumentsAction } = useStoreActions((actions) => actions);

  const passwordFields = usePasswordFields();

  useEffect(() => {
    setActiveDocumentsAction(["0", "1"]);
  }, [setActiveDocumentsAction]);

  return (
    <Page.Protected>
      <Document>
        <Accordion activeKey={activeDocuments}>
          <DocumentForm.User>
            <Field.List fields={passwordFields} />
          </DocumentForm.User>
          <br />
          <DocumentForm.Client />
          <br />
          <Button.AddDocuments />
        </Accordion>
      </Document>
    </Page.Protected>
  );
}

export default AddNewDocuments;
