import { useStoreActions, useStoreState } from "easy-peasy";
import Modal from "../../presentational/Modal";
import { Col, Form, Row } from "react-bootstrap";
import Button from "../../presentational/Button";
import tw from "twin.macro";

const Required = tw.span`text-red-400`;

function useModal() {
  const { showDuplicateDocumentsModal, user, documentDuplicationInput } =
    useStoreState((state) => state);

  const {
    setShowDuplicateDocumentsModalAction,
    setDocumentDuplicationInputAction,
    duplicateDocumentsThunk,
  } = useStoreActions((actions) => actions);

  return {
    show: showDuplicateDocumentsModal,
    onHide: () => setShowDuplicateDocumentsModalAction(false),
    title: `Duplicate Documents For ${user.document.name}`,
    name: documentDuplicationInput.name,
    onChangeName: (evt) =>
      setDocumentDuplicationInputAction({ name: evt.target.value }),
    email: documentDuplicationInput.email,
    onChangeEmail: (evt) =>
      setDocumentDuplicationInputAction({ email: evt.target.value }),
    password: documentDuplicationInput.password,
    onChangePassword: (evt) =>
      setDocumentDuplicationInputAction({ password: evt.target.value }),
    cpassword: documentDuplicationInput.cpassword,
    onChangeCPassword: (evt) =>
      setDocumentDuplicationInputAction({ cpassword: evt.target.value }),
    onClickDuplicate: () => duplicateDocumentsThunk(),
    disableButton:
      !documentDuplicationInput.name ||
      !documentDuplicationInput.email ||
      !documentDuplicationInput.password ||
      !documentDuplicationInput.cpassword,
  };
}

function ModalDuplicateDocuments(props) {
  const {
    name,
    onChangeName,
    email,
    onChangeEmail,
    password,
    onChangePassword,
    cpassword,
    onChangeCPassword,
    onClickDuplicate,
    disableButton,
    ...modalProps
  } = useModal();

  return (
    <Modal
      footer={
        <Button disabled={disableButton} onClick={onClickDuplicate}>
          {modalProps.title}
        </Button>
      }
      {...modalProps}
      {...props}
    >
      <Row>
        <Col xs={12} md={6}>
          <Form.Label>
            Name <Required>*</Required>
          </Form.Label>
          <Form.Control value={name} onChange={onChangeName} />
        </Col>

        <Col xs={12} md={6}>
          <Form.Label>
            Email <Required>*</Required>
          </Form.Label>
          <Form.Control type="email" value={email} onChange={onChangeEmail} />
        </Col>

        <Col xs={12} md={6}>
          <br />
          <Form.Label>
            Password <Required>*</Required>
          </Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={onChangePassword}
          />
        </Col>

        <Col xs={12} md={6}>
          <br />
          <Form.Label>
            Confirm Password <Required>*</Required>
          </Form.Label>
          <Form.Control
            type="password"
            value={cpassword}
            onChange={onChangeCPassword}
          />
        </Col>
      </Row>
    </Modal>
  );
}

export default ModalDuplicateDocuments;
