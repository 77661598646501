import Page from "../containers/Page";
import Center from "../presentational/Center";
import Form from "../containers/Form";

function PasswordReset() {
  return (
    <Page.Protected>
      <Center.Screen>
        <Form.PasswordReset />
      </Center.Screen>
    </Page.Protected>
  );
}

export default PasswordReset;
