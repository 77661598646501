import { thunk } from "easy-peasy";
import {
  upsertSettingAPICall,
  searchSettingsAPICall,
  getSettingAPICall,
} from "../../api/setting";

const thunks = {
  createSettingThunk: thunk(async (actions, { successMsg, ...setting }) => {
    actions.setLoadingAction(true);

    try {
      const response = await upsertSettingAPICall(setting);
      actions.setSettingDocumentAction(response.data);
      actions.setShowAddSettingModalAction(false);
      actions.resetFileStateAction();
      actions.setAlertThunk({
        type: "success",
        message: successMsg,
      });
    } catch (e) {
      console.log(e);
      actions.setAlertThunk({
        type: "error",
        message: e.response.data.message,
      });
    }

    actions.setLoadingAction(false);
  }),
  searchSettingsThunk: thunk(async (actions, queryJson) => {
    actions.setLoadingAction(true);

    try {
      const response = await searchSettingsAPICall(queryJson);
      actions.setSettingSearchResultsAction(response.data);
    } catch (e) {
      console.log(e);
      actions.setAlertThunk({
        type: "error",
        message: e.response.data.message,
      });
    }

    actions.setLoadingAction(false);
  }),
  getSettingThunk: thunk(async (actions, id) => {
    actions.setLoadingAction(true);

    try {
      const response = await getSettingAPICall(id);
      actions.setSettingDocumentAction(response.data);
      actions.resetFileStateAction();
    } catch (e) {
      console.log(e);
      actions.setAlertThunk({
        type: "error",
        message: e.response.data.message,
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
