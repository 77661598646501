const state = {
  activeDocuments: [],
  showDuplicateDocumentsModal: false,
  showDeleteDocumentsModal: false,
  documentDuplicationInput: {
    name: "",
    email: "",
    password: "",
    cpassword: "",
  },
};

export default state;
