import { action } from "easy-peasy";
import { defaultState } from "./state";

const actions = {
  setFileAction: action((state, file) => {
    state.file = {
      ...state.file,
      ...file,
    };
  }),
  resetFileStateAction: action((state) => {
    state.file = defaultState;
  }),
  setFileDocumentAction: action((state, document) => {
    state.file.document = {
      ...state.file.document,
      ...document,
    };
  }),
  setFileSearchResultsAction: action((state, searchResults) => {
    state.file.searchResults = searchResults;
  }),
  setShowAddFileModalAction: action((state, show) => {
    state.file.showAddFileModal = show;
  }),
  setFileSearchInputAction: action((state, input) => {
    state.file.searchInput = input;
  }),
};

export default actions;
