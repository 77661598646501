const defaultState = {
  searchInput: "",
  searchResults: [],
  showAddSettingModal: false,
  document: {
    name: "",
    baseURLDev: "http://localhost:8080",
    baseURLProd: "",
    _id: "",
    clientGuid: "",
  },
};

const state = {
  setting: defaultState,
};

export { state as default, defaultState };
