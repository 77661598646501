import { useStoreActions, useStoreState } from "easy-peasy";
import Modal from "../../presentational/Modal";
import { useNavigate } from "react-router-dom";
import Button from "../../presentational/Button";

function useModal() {
  const navigate = useNavigate();

  const { showDeleteDocumentsModal, user } = useStoreState((state) => state);

  const { setShowDeleteDocumentsModalAction, deleteDocumentsThunk } =
    useStoreActions((actions) => actions);

  return {
    show: showDeleteDocumentsModal,
    onHide: () => setShowDeleteDocumentsModalAction(false),
    title: `Delete Documents For ${user.document.name}`,
    onClickDuplicate: () => deleteDocumentsThunk(navigate),
  };
}

function ModalDeleteDocuments(props) {
  const { onClickDuplicate, ...modalProps } = useModal();

  return (
    <Modal
      footer={
        <Button variant="danger" onClick={onClickDuplicate}>
          {modalProps.title}
        </Button>
      }
      {...modalProps}
      {...props}
    >
      <div className="text-danger">
        Are you absolutely sure you want to delete this user, associated client,
        all associated settings, and all associated files? This cannot be
        undone!
      </div>
    </Modal>
  );
}

export default ModalDeleteDocuments;
